<template>
  <div class="main_content">
    <div class="title_box">
      <span></span>
      <h5>小组管理</h5>
    </div>
    <div class="line"></div>
    <div class="operate_box">
      <div class="search_box" v-if="this.$store.state.role === 'admin'">
        <div class="input_box">
          <span>地区：</span>
          <!--省市三级联动-->
          <!--<el-cascader-->
          <!--placeholder="请选择"-->
          <!--v-model="searchForm.regionCode"-->
          <!--:options="options"-->
          <!--ref="myCascader"-->
          <!--clearable-->
          <!--@change="getArea"-->
          <!--&gt;-->
          <!--</el-cascader>-->
          <el-select v-model="searchForm.regionCode" filterable @focus="getArea" clearable>
            <el-option v-for="item in options" :key="item.regionCode" :value="item.regionCode" :label="item.area" />
          </el-select>
        </div>
        <div class="input_box"><el-button type="primary" class="el-icon-search" @click="searchTable"></el-button></div>
      </div>
      <div class="operate_btn">
        <el-button type="primary" icon="el-icon-plus" @click="addTest">新增</el-button>
        <el-button type="danger" icon="el-icon-delete" @click="deleteTest">批量删除</el-button>
      </div>
    </div>
    <div class="tbale_box">
      <el-table v-loading="loading" :data="tableData" @selection-change="handleSelectionChange" border
        style="width: 100%" height="100%">
        <el-table-column type="selection" width="55" />
        <el-table-column prop="id" label="ID" width="100" />
        <el-table-column prop="name" label="名称" />
        <el-table-column prop="area" label="赛区" />
        <el-table-column prop="comName" label="社区" />
        <el-table-column prop="regionCode" label="地区" />
        <el-table-column prop="trueName" label="组长" width="100" />
        <el-table-column prop="num" label="已分配(人)" width="80" />
        <el-table-column prop="maxNum" label="上限人数" width="70" />
        <el-table-column prop="auth" label="已分配权限" width="160">
          <template slot-scope="scope">
            <div v-if="scope.row.auth.in_group_7 == 'on'">【组内前一半姓氏排序】</div>
            <div v-if="scope.row.auth.between_group == 'on'">【组间排名】</div>
            <div v-if="scope.row.auth.in_group == 'on'">【组内排名】</div>
          </template>
        </el-table-column>
        <el-table-column width="190" label="操作">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="查看" placement="top">
              <el-button icon="el-icon-view" circle @click="Check(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="编辑" placement="top"
              v-if="!scope.row.name.match(RegExp(/公共组/))">
              <el-button type="primary" icon="el-icon-edit" circle @click="editTest(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <el-button type="danger" icon="el-icon-delete" circle @click="deleteGroup(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="权限" placement="top">
              <el-button type="primary" icon="el-icon-lock" circle @click="setAuthority(scope.row)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageBox.pageNum"
      :page-sizes="[10, 20, 50, 100, 200, 300, 400]" :page-size="pageBox.pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="pageBox.total" />
    <!--新增编辑小组-->
    <el-dialog :visible.sync="editDialog" width="30%" :close-on-click-modal="false" :close-on-press-escape="false"
      class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>{{ operateBox.title }}
      </div>
      <edit-from :operateBox="operateBox" :key="new Date().getTime()" @closed="closed"></edit-from>
    </el-dialog>
    <!--      查看信息-->
    <el-dialog :visible.sync="CheckDialog" width="50%" :close-on-click-modal="false" :close-on-press-escape="false"
      class="set_dialog_style" @closed="closed">
      <div slot="title" class="dialog_title">
        <span></span>{{ operateBox.title }}
      </div>
      <check :operateBox="operateBox" :key="new Date().getTime()"></check>
    </el-dialog>
    <!--    权限设置-->
    <el-dialog :visible.sync="authorityDialog" width="580px" :close-on-click-modal="false"
      :close-on-press-escape="false" class="set_dialog_style">
      <div slot="title" class="dialog_title">
        <span></span>{{ operateBox.title }}
      </div>
      <authority :operateBox="operateBox" :key="new Date().getTime()" @closed="closed"></authority>
    </el-dialog>
  </div>
</template>

<script>
/* 设备告警 */
import api from '@/api/index'
import EditFrom from './components/edit-from'
import Check from './components/check'
import Authority from './components/authority'
//import { chinaArea } from '@/utils/china-area-data'
export default {
  name: 'group-manage',
  components: {
    Authority,
    Check,
    EditFrom,
  },
  data() {
    return {
      loading: false,
      startDate: '',
      endDate: '',
      tableData: [],
      editDialog: false,
      operateBox: {
        title: '新增',
        operate: 'add',
        id: null
      },
      CheckDialog: false,
      authorityDialog: false,
      area: "",
      searchForm: {
        regionCode: null,
        communityIds: null
      },
      pageBox: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      groupIds: [],
      delGroupIds: null,
      options: null
    }
  },
  created() {
    this.fetchUserPower()
  },
  mounted() {

  },
  methods: {
    //根据UserID回显权限res,拿到医生权限管理的社区
    async fetchUserPower() {
      try {
        let res = await api.getUserPower(this.$store.state.userId)
        if (res.code == 200) {
          if (this.$store.state.role !== 'admin') {
            this.searchForm.communityIds = res.data.comId
          }
          this.getGroupList(this.searchForm, this.pageBox)
        }
      } catch (e) {

      }
    },
    //获取小组列表
    async getGroupList(data, page) {
      this.loading = true
      try {
        let res = await api.getGroupListes(data, page)
        if (res.code == 200) {
          console.log(res);
          res.rows.forEach(item => {
            item.auth = JSON.parse(item.auth)
          })
          this.tableData = res.rows
          this.pageBox.total = res.total
        } else {
          this.$message.error(res.msg)
        }
      } catch (e) {
        this.$message.error(e.msg)
      } finally {
        this.loading = false
      }
    },
    //根据地区查询社区
    getArea() {
      this.options = this.$store.state.userArea
      // if(this.$refs.myCascader.getCheckedNodes().length>0){
      //   const areaCode = this.$refs.myCascader.getCheckedNodes()[0].value
      //   this.searchForm.regionCode=areaCode
      // }else {
      //   this.searchForm.regionCode=null
      // }
    },
    /* 一页显示多少条切换 */
    handleSizeChange(val) {
      this.pageBox.pageSize = val
      this.getGroupList(this.searchForm, this.pageBox)
    },
    /* 分页切换 */
    handleCurrentChange(val) {
      this.pageBox.pageNum = val
      this.getGroupList(this.searchForm, this.pageBox)
    },
    // 新增样例
    addTest() {
      this.operateBox = {
        title: '新增',
        operate: 'add',
        id: null
      }
      this.editDialog = true
    },
    editTest(row) {
      console.log(row)
      this.operateBox = {
        title: '编辑',
        operate: 'edit',
        id: row.id
      }
      this.editDialog = true
    },
    //查看信息
    Check(row) {
      let str = row.name
      let reg = RegExp(/公共组/)
      let isPublick = reg.test(str)
      this.operateBox = {
        title: '小组成员',
        operate: 'check',
        id: row.id,
        isPublick: isPublick
      }
      this.CheckDialog = true
    },
    setAuthority(row) {
      this.operateBox = {
        title: "权限设置",
        operate: 'authority',
        row: row
      }
      this.authorityDialog = true
    },
    //选中要删除的小组获取对应的id
    handleSelectionChange(val) {
      let ids = []
      val.forEach(item => {
        ids.push(item.id)
      })
      this.delGroupIds = { ids: ids }
    },
    //批量删除按钮
    deleteTest() {
      if (this.delGroupIds == null) {
        this.$message.info('请选择删除项')
        return false
      }
      this.$confirm('确定删除该数据吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delGroupInfo(this.delGroupIds)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    deleteGroup(row) {
      this.$confirm('确定删除该数据吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delGroupInfo({ ids: [row.id] })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    //删除或批量删除小组
    async delGroupInfo(ids) {
      try {
        let res = await api.delGroup(ids)
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '删除成功'
          })
          this.getGroupList(this.searchForm, this.pageBox)
        } else {
          this.$message({
            type: 'warning',
            message: res.msg
          })
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    //条件查询
    searchTable() {
      this.getGroupList(this.searchForm, this.pageBox)
    },
    //关闭弹窗
    closed() {
      this.editDialog = false
      this.authorityDialog = false
      this.CheckDialog = false
      this.getGroupList(this.searchForm, this.pageBox)
    }
  },
  watch: {}
}
</script>

<style scoped lang="less">
.el-pagination {
  text-align: center;
}

/*页面特殊样式*/
.one_tag,
.two_tag,
.three_tag {
  padding: 5px 12px;
  border-bottom-right-radius: 24px;
  border-top-left-radius: 24px;
  color: #fff;
}

.one_tag {
  background: #c60c00;
  background: -webkit-linear-gradient(to bottom, #eb1610, #c66334);
  background: linear-gradient(to bottom, #eb1600, #c65f00);
}

.two_tag {
  background: #eb6601;
  background: -webkit-linear-gradient(to bottom, #eb6601, #c6aa07);
  background: linear-gradient(to bottom, #eb6601, #c6aa07)
}

.three_tag {
  background: #ebd007;
  background: -webkit-linear-gradient(to bottom, #ebd007, #bec606);
  background: linear-gradient(to bottom, #ebd007, #bec606)
}
</style>
