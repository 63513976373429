<template>
  <div class="form_box">
    <div>
      <el-checkbox v-model="checked1" label="组内排名" border @change="changeChecked1" />
      <el-checkbox v-model="checked2" label="组内前一半姓氏排名" @change="changeChecked2" border />
      <el-checkbox v-model="checked3" label="组间排名" border />
    </div>
    <el-button type="primary" style="margin-top: 20px" @click="submitBtn">确认修改</el-button>
  </div>
</template>

<script>
import api from '@/api/index'
export default {
  props: {
    operateBox: {
      type: Object,
      required: true
    }
  },
  name: 'authority',
  data() {
    return {
      checked1: false,
      checked2: false,
      checked3: false,
      authList: {
        between_group: "off",
        in_group_7: "off",
        in_group: "off"
      }
    }
  },
  created() {
    if (this.operateBox.row.auth.in_group == "on") {
      this.checked1 = true
      // this.checked2 = false
    } else {
      this.checked1 = false
    }
    if (this.operateBox.row.auth.in_group_7 == "on") {
      this.checked2 = true
      // this.checked1 = false
    } else {
      this.checked2 = false
    }
    if (this.operateBox.row.auth.between_group == "on") {
      this.checked3 = true
    } else {
      this.checked3 = false
    }
  },
  methods: {

    //不确定是不是1和2只能生效一个，为了满足默认情况，先注释掉
    changeChecked1(e) {
      // if (e) {
      //   this.checked2 = false
      // }
    },
    changeChecked2(e) {
      // if (e) {
      //   this.checked1 = false
      // }
    },
    async submitBtn() {
      if (this.checked1) {
        this.authList.in_group = "on"
      } else {
        this.authList.in_group = "off"
      }
      if (this.checked2) {
        this.authList.in_group_7 = "on"
      } else {
        this.authList.in_group_7 = "off"
      }
      if (this.checked3) {
        this.authList.between_group = "on"
      } else {
        this.authList.between_group = "off"
      }
      // this.operateBox.row.auth=JSON.stringify(this.authList)
      let data = {
        auth: JSON.stringify(this.authList),
        id: this.operateBox.row.id
      }
      console.log(data)
      try {
        let res = await api.groupUpdate(data)
        if (res.code == 200) {
          this.$message.success("设置成功！")
          this.$emit("closed")
        } else {
          this.$message.error(res.msg)
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    }
  },
}
</script>

<style scoped>
.form_box {
  padding: 50px;
}
</style>
