<template>
  <div class="form_box">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="小组名称" prop="name">
        <el-input v-model="ruleForm.name" :maxlength="20" />
      </el-form-item>
      <el-form-item label="最大人数" prop="maxNum">
        <el-input v-model="ruleForm.maxNum" placeholder="最多默认200人" type="number" :maxlength="3" @input="handleInput" />
      </el-form-item>
      <el-form-item label="赛区" prop="area">
        <el-input v-model="ruleForm.area" :maxlength="20" />
      </el-form-item>
      <div style="display: flex">
        <el-form-item label="社区选择" prop="regionCode" style="flex: 2">
          <!--省市三级联动-->
          <!--<el-cascader-->
          <!--placeholder="请选择"-->
          <!--v-model="ruleForm.regionCode"-->
          <!--:options="options"-->
          <!--ref="myCascader"-->
          <!--@change="getAreaCode"-->
          <!--&gt;-->
          <!--</el-cascader>-->
          <el-select v-model="ruleForm.regionCode" filterable clearable @change="getAreaCode">
            <el-option v-for="item in options" :key="item.regionCode" :label="item.area" :value="item.regionCode" />
          </el-select>
        </el-form-item>
        <el-form-item prop="communityId" filterable style="flex: 1;margin-left: 10px" label-width="0">
          <el-select v-model="ruleForm.communityId">
            <el-option v-for="item in communityList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
      </div>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button @click="cancelFrom('ruleForm')">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
//import { chinaArea } from '@/utils/china-area-data'
import api from '@/api/index'
import debounce from 'lodash/debounce';
export default {
  props: {
    operateBox: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ruleForm: {
        name: '',
        maxNum: '',
        area: '',
        communityId: '',
      },
      rules: {
        name: [{ required: true, message: '请输入小组名称', trigger: 'change' }],
        maxNum: [{ required: true, message: '请输入小组最大人数', trigger: 'change' },
        {
          validator: (rule, value, callback) => {
            const num = parseFloat(value);
            if (!isNaN(num) && num >= 1 && num <= 200) {
              callback();
            } else {
              callback(new Error("范围应在1到200之间"));
            }
          },
          trigger: "change"
        }],
        area: [{ required: true, message: '请填写赛区', trigger: 'change' }],
        regionCode: [{ required: true, message: '请选择所在社区', trigger: 'change' }],
        communityId: [{ required: true, message: '请选择所在社区', trigger: 'change' }],
      },
      communityList: [],
      areaCode: '',
      options: null
    };
  },
  created() {
    this.options = this.$store.state.userArea
    if (this.operateBox.id != null) {
      this.getGroupInfo(this.operateBox.id)
    }
  },
  methods: {
    handleInput(event) {
      // 只允许输入数字，且不为空
      this.ruleForm.maxNum = event.replace(/\D/g, '');
    },
    //根据小组id查询小组详情
    async getGroupInfo(id) {
      try {
        let res = await api.getGroupInfoById(id)
        if (res.code == 200) {
          this.ruleForm = res.data
          this.getCommuintyList(res.data.regionCode)
        }
      } catch (e) {

      }
    },
    submitForm: debounce(function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = this.ruleForm
          if (this.operateBox.operate == 'add') {
            let data = {
              name: this.ruleForm.name,
              maxNum: Number(this.ruleForm.maxNum),
              area: this.ruleForm.area,
              communityId: this.ruleForm.communityId,
            }
            this.addGroup(data)
          } else {
            let data = {
              id: this.ruleForm.id,
              name: this.ruleForm.name,
              maxNum: Number(this.ruleForm.maxNum),
              area: this.ruleForm.area,
              communityId: this.ruleForm.communityId,
            }
            this.updateGroup(data)
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }, 500),
      getAreaCode(val) {
      console.log(val)
      //const areaCode = this.$refs.myCascader.getCheckedNodes()[0].value
      this.ruleForm.communityId = null
      this.getCommuintyList(val)
    },
      async getCommuintyList(code) {
      let data = {
        code: code,
      }
      let page = {
        pageSize: 99999,
        pageNum: 1
      }
      try {
        let res = await api.getCommunitySystemUser(data, page)
        if(res.code == 200) {
  this.communityList = res.rows
}
      } catch (e) {

}
    },
    async addGroup(p) {
  try {
    let res = await api.groupAdd(p)
    if (res.code == 200) {
      this.$message.success('新增成功！')
      this.$emit('closed')
    } else {
      this.$message.error(res.msg)
    }
  } catch (e) {
    this.$message.error(e.msg)
  }
},
    async updateGroup(p) {
  try {
    let res = await api.groupUpdate(p)
    if (res.code == 200) {
      this.$message.success('更新成功！')
      this.$emit('closed')
    } else {
      this.$message.error(res.msg)
    }
  } catch (e) {
    this.$message.error(e.msg)
  }
},
cancelFrom(formName) {
  this.$emit('closed')
}
  }
}
</script>

<style scoped>
.el-cascader {
  width: 100%
}
</style>
