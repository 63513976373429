<template>
  <div>
    <div class="tbale_box">
      <el-table :data="tableData" border style="width: 100%" height="500">
        <el-table-column fixed prop="id" label="ID" width="50" />
        <el-table-column prop="weixinName" label="微信名" width="150" />
        <el-table-column prop="trueName" label="姓名" width="80" />
        <el-table-column prop="phoneNumber" label="电话" />
        <el-table-column prop="sex" label="性别" width="50">
          <template slot-scope="scope">
            <span v-if="scope.row.sex == 1">男</span>
            <span v-else>女</span>
          </template>
        </el-table-column>
        <el-table-column prop="age" label="年龄" width="60" />
        <el-table-column prop="groupRole" label="角色" width="140">
          <template slot-scope="scope">
            <span v-if="scope.row.groupRole == 2">组长</span>
            <span v-else-if="scope.row.groupRole == 1">副组长</span>
            <span v-else>组员</span>
          </template>
        </el-table-column>
        <el-table-column width="240" label="操作">
          <template slot-scope="scope">
            <el-button type="danger" size="mini" round @click="removeUser(scope.row)"
              v-if="!operateBox.isPublick">移除</el-button>
            <el-button type="primary" size="mini" round @click="setGroupLeader(scope.row)">组长</el-button>
            <el-button type="primary" size="mini" round @click="setDeputyUser(scope.row)">副组长</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageBox.pageNum"
      :page-sizes="[10, 20, 50, 100, 200, 300, 400]" :page-size="pageBox.pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="pageBox.total" />

    <!-- <new-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :page-size=pageBox.pageSize
      layout="total,sizes, prev, pager, next" :total=pageBox.total>
    </new-pagination> -->
  </div>
</template>

<script>
import api from '@/api/index'
export default {
  props: {
    operateBox: {
      type: Object,
      required: true
    }
  },
  name: 'check',
  data() {
    return {
      tableData: [],
      total: 0,
      tablePape: {
        groupId: ''
      },
      pageBox: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      }
    }
  },
  created() {
    this.tablePape.groupId = this.operateBox.id
    this.getResidents(this.tablePape, this.pageBox)
  },
  mounted() {

  },
  methods: {
    async getResidents(data, page) {
      try {
        let res = await api.getResidentList(data, page)
        if (res.code == 200) {
          this.tableData = res.rows
          this.pageBox.total = res.total
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
    /* 一页显示多少条切换 */
    handleSizeChange(val) {
      this.pageBox.pageSize = val
      this.getResidents(this.tablePape, this.pageBox)
    },
    /* 分页切换 */
    handleCurrentChange(val) {
      this.pageBox.pageNum = val
      this.getResidents(this.tablePape, this.pageBox)
    },

    //移除组员
    //移除会将组员放置在该社区公共组内
    removeUser(row) {
      row.groupId = ''
      this.$confirm('移除该用户会影响小组积分和排名，确定移除该数据吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          "groupId": 0,
          "groupRole": 0,
          "id": row.id
        }
        this.groupLeaderSet(data, '移除成功')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消移除'
        });
      });

    },
    //设置副组长
    setGroupLeader(row) {
      row.groupRole = 2
      let data = {
        "groupId": this.operateBox.id,
        "groupRole": 2,
        "id": row.id
      }
      this.groupLeaderSet(data, '设置组长成功')
    },
    //设置副组长
    setDeputyUser(row) {
      let data = {
        "groupId": this.operateBox.id,
        "groupRole": 1,
        "id": row.id
      }
      this.groupLeaderSet(data, '设置副组长成功')
    },
    //修改居民在小组中的状态信息
    async groupLeaderSet(data, tips) {
      try {
        let res = await api.setGroupRole(data)
        if (res.code == 200) {
          this.$message.success(tips)
          this.getResidents(this.tablePape, this.pageBox)
        } else {
          this.$message.error(res.msg)
        }
      } catch (e) {
        this.$message.error(e.msg)
      }
    },
  },
  watch: {}
}
</script>

<style scoped>
.el-pagination {
  text-align: center;
}
</style>
